import React from 'react';
import { graphql, Link } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import WorkDetail from '../components/work-detail';

import Layout from '../components/layout';

// import ProjectsDetail from '../components/projects-detail';
// import SkillsDetail from '../components/skills-detail';

const shortcodes = { Link };

export const DetailPageTemplate = ({ page }) => {
  switch (page.fields.contentType) {
    case 'work':
      return (
        <WorkDetail {...page.frontmatter}>
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{page.body}</MDXRenderer>
          </MDXProvider>
        </WorkDetail>
      );
    // case 'projects':
    //   return <ProjectsDetail />;
    // case 'skills':
    //   return <SkillsDetail />;
    default:
      return null;
  }
};

const DetailPage = ({ data }) => {
  const {
    page,
    page: { frontmatter },
  } = data;
  return (
    <Layout
      meta={frontmatter.meta || false}
      title={frontmatter.title || false}
      slug={frontmatter.slug || false}
      backLink={page.fields.contentType}
    >
      <DetailPageTemplate {...data} />
    </Layout>
  );
};

export default DetailPage;

export const pageQuery = graphql`
  query DetailPage($id: String!) {
    page: mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        slug
        template
        featuredImage
        meta {
          title
          description
        }

        ...Work
      }
      fields {
        contentType
      }
    }
  }
`;
